import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '../shared/auth.guard';
import { UserRole } from '../shared/auth.roles';
//import { ReportViewerComponent } from './app/report-viewer/report-viewer.component';
import { ReportViewerComponent } from './app/biz-shipping-app/shipping-reports/all-reports/report-viewer/report-viewer.component';
//./app/ /reports-tms/all-reports/report-viewer/report-viewer.component'
import { ReportGridViewerComponent } from './app/report-grid-viewer/report-grid-viewer.component';
//0import { PpdControllerComponent } from './app/general-forms/ppd-controller/ppd-controller.component';

const adminRoot = environment.adminRoot.substr(1); // path cannot start with a slash

let routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: adminRoot,
    loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    data: { roles: [UserRole.Admin, UserRole.Editor] },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  
  { path: 'ReportViewer', component: ReportViewerComponent },
  //{ path: '101/101101/101101106', component: PpdControllerComponent },
  //{ path: 'reportGridViewer', component: ReportGridViewerComponent },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/error' },
];

if (!environment.isAuthGuardActive) {
  routes = [
    {
      path: '',
      component: HomeComponent,
      pathMatch: 'full',
    },
    {
      path: 'APP',
      loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    },
    { path: 'ReportViewer', component: ReportViewerComponent },
    //{ path: '101/101101/101101106', component: PpdControllerComponent },

    {
      path: 'user',
      loadChildren: () =>
        import('./user/user.module').then((m) => m.UserModule),
    },
    { path: 'error', component: ErrorComponent },
    { path: '**', redirectTo: '/error' },
  ];
}
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewRoutingModule { }
