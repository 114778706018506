

import { BaseService } from '../../../../@core/Services/base.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Component, Input, ViewChild, OnInit, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Enum } from '../../../../@core/components/config';
import { BaseComponent } from '../../../../@core/BaseComponent/base.component';
import { ExcelService } from '../../../../@core/Services/export.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    
    selector: 'report-viewer',
    templateUrl: 'report-viewer.component.html',
    
})
export class ReportViewerComponent implements OnInit {
    pdfFile: any;
    constructor(public sanitizer: DomSanitizer, private baseService: BaseService, private activeroute: ActivatedRoute, private _ExcelService: ExcelService,
        private loader: NgxUiLoaderService) {
    }
    async ngOnInit() {
        debugger
        setTimeout(() => {

            this.activeroute.queryParams.subscribe(async (params) => {

                if (params.obj) {
                    debugger
                    let data = JSON.parse(params.obj)
                    await this.getreportfile(data)
                }
            })
        }, 0);
    }

    async getreportfile(data) {
        debugger
        if (data) {
            this.loader.start();
            debugger
            await this.baseService.postReport('ReportBuilder', 'XportReport', data, 'Report').then((o: any) => {
                debugger
                this.loader.stop();
                if (o) {

                    var blob = new Blob([o], { type: "application/pdf" });
                    const blobUrl = URL.createObjectURL(blob);
                    this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);

                }
            })
        }
    }
}

