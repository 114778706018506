<!--component html goes here -->
<!--component html goes here -->
<div class="card mb-4" *ngIf="pdfFile">
    <div class="card-body">
        <div class="row">
            <div class="col-lg-12">
                <iframe width="100%" height="1000" [src]="pdfFile"></iframe>
            </div>
        </div>
    </div>
</div>